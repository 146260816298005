<template>
  <h1>Agent login redirect</h1>
</template>

<script lang="ts">
import Vue from "vue";
import { getDefaultLocale } from "~/lib/utils/locale/getDefaultLocale";
import { getRegionalisedAgentPortalUrl } from "~/lib/utils/locale/getRegionalisedAgentPortalUrl";

export default Vue.extend({
  middleware({ redirect, i18n }) {
    const localeCode = i18n.locale ?? getDefaultLocale().code;
    redirect(301, getRegionalisedAgentPortalUrl(localeCode));
  },
});
</script>
