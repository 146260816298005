import { getLocaleByCode } from "./getLocaleByCode";

export const getRegionalisedAgentPortalUrl = (localeCode: string): string => {
  const agentPortalLoginUrl =
    process.env.AGENT_PORTAL_LOGIN_URL ??
    "https://bookings.intrepidtravel.com/bookings/@locale/agent/login";
  const locale = getLocaleByCode(localeCode);

  return agentPortalLoginUrl.replace(/@locale/i, locale.agentPortalLocale);
};
